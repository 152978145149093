<template>
    <div class="home">
      <el-menu :default-active="formInline.state+''" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="-1">全部</el-menu-item>
        <el-menu-item index="0">待受理</el-menu-item>
        <el-menu-item index="1">处理中</el-menu-item>
        <el-menu-item index="2">待付款</el-menu-item>
        <el-menu-item index="3">投诉完成</el-menu-item>
        <el-menu-item index="4">索赔成功</el-menu-item>
        <el-menu-item index="-2">已取消</el-menu-item>
      </el-menu>
      <div class="pt20"></div>
     <el-form :inline="true" :model="formInline" style="position: relative;" class="demo-form-inline">
            <el-form-item label="下次处理时间">
                <el-date-picker
                    v-model="timevalue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="订单创建时间">
                <el-date-picker
                    v-model="timevalue2"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="下次处理原因">
            <el-select style="width:200px;"  v-model="status" placeholder="请选择">
              <el-option
                    label="请选择"
                    :value="0">
                  </el-option>
                  <el-option
                    v-for="ntr in nextreason"
                    :key="ntr.value"
                    :label="ntr.name"
                    :value="ntr.value">
                  </el-option>
            </el-select>
            </el-form-item><br>
            <el-form-item style="font-weight: bold;" label="投诉类型">
            <el-select @change="ScxType(formInline.cptype)" v-model="formInline.cptype" style="margin-right: 10px;" class="select1" placeholder="请选择">
                    <el-option
                      label="请选择"
                      :value="''">
                    </el-option>
                    <el-option
                      v-for="(cxt,i) in cxType"
                      :key="'e'+i"
                      :label="cxt.name"
                      :value="cxt.value">
                    </el-option>
                  </el-select>
                  <el-select :disabled='cxTypeOne.length == 0 ? true : false'   v-model="formInline.reason" class="select1" placeholder="请选择">
                    <el-option
                      v-for="(cxt,i) in cxTypeOne"
                      :key="'f'+i"
                      :label="cxt.name"
                      :value="cxt.value">
                    </el-option>
                  </el-select>
              </el-form-item>
            <el-form-item style="font-weight: bold;" label="订单类型">
              <el-select  v-model="formInline.order_group" class="select1" placeholder="请选择">
                    <el-option
                      v-for="(ot,i) in orderType"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.query" style="width:400px" placeholder="姓名、证件名、手机、航空公司、订单编号、联系邮箱"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="onSubmit('CHA')">查询</el-button>
           <el-button  type="info"   @click="exportlist">导出</el-button>
            </el-form-item>
      </el-form> 
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
        @selection-change="handleSelectionChange"
         >
       <el-table-column type="selection" width="55" align="center"/>
        <el-table-column
          prop="cdate"
          width="170"
          label="创建工单时间">
        </el-table-column>
        <el-table-column
          prop="order_no"
          width="185"
          label="订单编号">
        </el-table-column>
        <el-table-column
          prop="name"
          width="80"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="fcom"
          label="航空公司">
        </el-table-column>
        <el-table-column
          prop="cptype_text"
          width="100"
          label="投诉类型">
        </el-table-column>
        <el-table-column
          prop="order_group_text"
          width="100" 
          label="订单类型">
        </el-table-column>
        <el-table-column
          width="120"
          label="订单状态">
          <template  slot-scope="scope"><!--status  状态：1正常 0禁用 -1：删除 -2:未激活 0应该就是取消 -->
            {{scope.row.state_text}}{{scope.row.status==0?'(已取消)':scope.row.status==-1?'(已删除)':''}}
          </template>
        </el-table-column>
        <el-table-column
          prop="next_processing_time"
          width="100" 
          label="跟踪时间">
        </el-table-column>
        <el-table-column
          prop="next_processing_status_text" 
          width="100"
          label="跟踪原因">
        </el-table-column>
        <el-table-column
          prop="platform"
          width="90" 
          label="来源渠道">
        </el-table-column>
        <el-table-column
          prop="operator_name.account"
          width="80" 
          label="操作人">
        </el-table-column>
        <el-table-column
          width="150"
          label="操作">
          <!--
            status 为0时 显示查看
            status 为-1时显示查看，编辑
            state  为 0，1，2其中之一时显示编辑，删除
            state  为3或4时显示查看编辑删除
            以上逻辑从上到下判断满足前一条件就不继续
            在角色赋予权限之内  
           -->
          <template  slot-scope="scope">
            <div v-if="scope.row.status == 0">
              <el-button v-if="detail == 1" @click="centerDialog(scope.row.order_id,1)" size="mini" type="info" plain>查看</el-button>
            </div>
            <div v-else-if="scope.row.status == -1">
              <el-button v-if="detail == 1" @click="centerDialog(scope.row.order_id,1)" size="mini" type="info" plain>查看</el-button>
              <el-button v-if="edit == 1" @click="centerDialog(scope.row.order_id,2)" size="mini" type="info" plain>编辑</el-button>
            </div>
            <div v-else-if="scope.row.state == 0 || scope.row.state == 1 || scope.row.state == 2">
              <el-button v-if="edit == 1" @click="centerDialog(scope.row.order_id,2)" size="mini" type="info" plain>编辑</el-button>
              <el-button v-if="detele == 1" @click="deleteorder(scope.row.order_id,scope.row.time)" size="mini" type="danger" plain>删除</el-button>
            </div>
            <div v-else-if="scope.row.state == 3 || scope.row.state == 4">
              <el-button v-if="detail == 1" @click="centerDialog(scope.row.order_id,1)" size="mini" type="info" plain>查看</el-button>
              <el-button v-if="edit == 1" @click="centerDialog(scope.row.order_id,2)" size="mini" type="info" plain>编辑</el-button>
              <el-button v-if="detele == 1" @click="deleteorder(scope.row.order_id,scope.row.time)" size="mini" type="danger" plain>删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          :page-sizes="[10, 20, 30, 40 ,50]"
          layout="sizes,total,prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        
      <el-dialog
        title="订单"
        :visible.sync="centerDialogVisible"
        :close-on-click-modal = "false"
        :fullscreen="false"
        :modal="false"
        width="95%"
        hight="90%"
        center>
        <list2 style="min-height: 80vh;" @child-event="closeDialog" ref="centerDialogVisiblechild" :order_id='order_id'></list2>
      </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'
import list2 from '../order/list2.vue'
import globla from '../../globla'
export default {
  name: 'Home',
  components: {
    list2
  },
  data(){
    return {
      order_id:'',
      centerDialogVisible: false,
      loading:true,
      edit:location.edit,
      detail:location.detail,
      detele:location.detele,
      total:0,
      per_page: 10,
      current_page:1,
      tableData: [],
      nextreason:[],
      options:[],
      options2:[],
      formInline: {
         state:-1,
         del_stime:'',
         del_etime:'',
         next_processing_status:'',
         c_stime:'',
         c_etime:'',
         query:'',
         page:1,    
         order_group:'',
         cptype:'',
         reason:'',
      },
      status:'',
      timevalue:'',
      timevalue2:'',
      // 选中数组 
      ghs: [],
      nums:[],
      cxType:[],
      cxTypeOne:[],
      
      orderType:[],//订单类型
     //选中的记录数量
     initdata:{
         state:-1,
         del_stime:'',
         del_etime:'',
         next_processing_status:'',
         c_stime:'',
         c_etime:'',
         query:'',
         page:1,    
         order_group:'',
         cptype:'',
         reason:'',
     },
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick',  [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  methods: {
      goNav(url){
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
        this.$router.push(url);
      },
      exportlist(){
        let _this = this;
          _this.formInline.del_stime = '';
          _this.formInline.del_etime = '';
          if(_this.timevalue){
            _this.formInline.del_stime = formatDate(_this.timevalue[0]);
            _this.formInline.del_etime = formatDate(_this.timevalue[1]);
          }
          _this.formInline.c_stime = '';
          _this.formInline.c_etime = '';
          if(_this.timevalue2){
            _this.formInline.c_stime = formatDate(_this.timevalue2[0]);
            _this.formInline.c_etime = formatDate(_this.timevalue2[1]);
          }
          _this.formInline.next_processing_status = _this.status?_this.status:'';
          // if(_this.ghs.length == 0){
          //   _this.$message.error('请勾选您要导出的内容!');
          //   return; 
          // }
          location.href= `${globla.SERVE_URL+'/'+config.order_export}?order_id=${_this.ghs.toString() ? _this.ghs.toString() : ''}&state=${_this.formInline.state}
          &del_stime=${_this.formInline.del_stime}&del_etime=${_this.formInline.del_etime}&next_processing_status=${_this.formInline.next_processing_status}
          &c_stime=${_this.formInline.c_stime}&c_etime=${_this.formInline.c_etime}&order_group=${_this.formInline.order_group}
          &cptype=${_this.formInline.cptype}&reason=${_this.formInline.reason}&query=${_this.formInline.query}&api_token=${localStorage.getItem('Authorization')}`;
       
      },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        this.formInline.limit = _this.per_page;
          _this.formInline.del_stime = '';
          _this.formInline.del_etime = '';
          if(_this.timevalue){
            _this.formInline.del_stime = formatDate(_this.timevalue[0]);
            _this.formInline.del_etime = formatDate(_this.timevalue[1]);
          }
          _this.formInline.c_stime = '';
          _this.formInline.c_etime = '';
          if(_this.timevalue2){
            _this.formInline.c_stime = formatDate(_this.timevalue2[0]);
            _this.formInline.c_etime = formatDate(_this.timevalue2[1]);
          }
          _this.formInline.next_processing_status = _this.status?_this.status:'';
        axios.get(config.order,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      handleSelect(key) {
        this.formInline.state = key;
        this.onSubmit('CHA');
      },
      closeDialog(){
        this.centerDialogVisible = false;
        this.onSubmit('CHA');
      },
      centerDialog(order_id,type){
        this.order_id = order_id;
        sessionStorage.setItem("order_id", order_id);
        sessionStorage.setItem("list_type", type);
        sessionStorage.setItem("frist", true);
        this.centerDialogVisible = true;
        if(this.$refs.centerDialogVisiblechild){
          this.$refs.centerDialogVisiblechild.childMethod();
        }
        // let routeData = this.$router.resolve({
        //     name:"articlelistv2",
        // })   
        
        // window.open(routeData.href, '_blank'); 
      },
      getcttype(type){
        let _this = this;
        axios.get(config.cptype,{params:{type:type}})//投诉类型(包括123级联动)
        .then(function (response) {
            if(response.data.code == 200){
              _this.cxType = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      deleteorder(order_id,time){
        let _this = this;
        axios.delete(config.order,{data:{order_id:order_id,time:time}})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.onSubmit();
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
      },
      ScxType(type){
        let _this = this;
          _this.cxTypeOne = [];
          _this.formInline.reason = '';
         if(type === '') { return }
        axios.get(config.cpTypeChild,{params:{type:type}})//投诉类型(包括123级联动) 
        .then(function (response) {
            if(response.data.code == 200){
              _this.cxTypeOne = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page * 1;
        this.current_page = page.current_page;
      },typeIndex(index) {
        let _this = this; //处理分页数据的 index 
        return (_this.formInline.page - 1) * 20 + index + 1;
      },
      handleSizeChange(val){
        this.per_page = val*1;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
    // 多选框选中数据 
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.order_id)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
    init(){
        let _this = this;
        let a = sessionStorage.getItem('formInline');
        this.formInline = a?JSON.parse(a):this.initdata;
        axios.get(config.order,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data);
              gos();
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        function gos(){
          
        axios.get(config.next_reason)
        .then(function (response) {
            if(response.data.code == 200){
              _this.nextreason = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        
        axios.get(config.order_type)//订单类型
        .then(function (response) {
            if(response.data.code == 200){
              _this.orderType = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        _this.getcttype(0);//投诉类型(包括123级联动) 
        
      }
    }
  },
  created(){
      this.init();
  }
}
</script>
<style>
.el-dialog{
  margin-top: 5vh !important;
}
</style>
<style scoped>
.left{
    width: 70px;
    text-align: left;
}

.el-range-editor.el-input__inner{
  width: 300px;
}
</style>